import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const ParaText = styled.p`
  width: 100%;
  font-family: 'Gotham-Regular', arial, sans-serif !important;
  font-size: 17px;
  line-height: 32px;
  margin-left: auto !important;
  margin-right: auto !important;
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    font-size: 19px;
    line-height: 27px;
  }
`;
export const HeaderH3 = styled.h3`
  position: relative;
  margin: 0px 0px 40px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: rgb(0, 105, 170);
  text-align: center;
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 20px;
    line-height: 22px;
  }
`;
